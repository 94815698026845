import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

import router from '../router'

export function useAuth() {
  async function login(email: string, password: string) {
    const auth = getAuth()
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (e) {
      alert('Wrong username or password!')
      console.error('Login failed!', e)
    }
    router.push('/')
  }

  async function logout() {
    const auth = getAuth()
    try {
      await signOut(auth)
    } catch (e) {
      console.error('Logout failed...? 😲')
    }
    if (!auth.currentUser) {
      router.push('/login')
    }
  }

  return { login, logout }
}
