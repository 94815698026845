<template>
  <div class="flex flex-col space-y-4 items-center justify-center h-full">
    <img src="@/assets/logo.svg" alt="UL logo" class="h-16" />
    <input
      type="email"
      v-model.trim="email"
      placeholder="E-post"
      class="border-2 rounded focus:outline-none border-gray-300 focus:border-blue-600 p-4 w-96 bg-gray-100"
    />
    <input
      type="password"
      v-model="password"
      placeholder="Passord"
      class="border-2 rounded focus:outline-none border-gray-300 focus:border-blue-600 p-4 w-96 bg-gray-100"
    />
    <button
      class="border-2 rounded focus:outline-none border-gray-300 hover:border-blue-600 focus:border-blue-600 active:border-blue-800 bg-gray-200 hover:bg-blue-200 focus:bg-blue-200 active:bg-blue-300 text-gray-800 hover:text-blue-900 focus:text-blue-900 py-4 px-6 font-semibold"
      @click="handleLogin"
    >
      Logg inn
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useAuth } from '../hooks/useAuth'

const auth = useAuth()

const email = ref('')
const password = ref('')

function handleLogin() {
  auth.login(email.value, password.value)
}
</script>

<style lang="scss"></style>
