import { defineStore } from 'pinia'

import { useAboutStore } from './about'
import { useContactStore } from './contact'
import { useEventStore } from './events'
import { useInformationStore } from './information'
import { useResourceStore } from './resources'

export const useRootStore = defineStore('root', () => {
  async function populate() {
    await Promise.all([
      useAboutStore().populate(),
      useEventStore().populate(),
      useInformationStore().populate(),
      useResourceStore().populate(),
      useContactStore().populate(),
    ])
  }

  return { populate }
})
