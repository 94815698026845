<template>
  <div class="divide-y divide-gray-400">
    <div
      v-for="(aboutArticle, index) in aboutArticles"
      :key="aboutArticle.id"
      class="p-4 space-y-4 w-full max-w-screen-xl mx-auto"
    >
      <div
        class="flex flex-col mx-auto justify-center text-center md:text-left"
        :class="index % 2 ? 'md:flex-row' : 'md:flex-row-reverse '"
      >
        <div v-if="aboutArticle.image" class="w-full md:w-1/2 p-6 sm:p-12 max-h-screen">
          <img class="w-full rounded-xl shadow" :src="aboutArticle.image" alt="Artikkelbilde" />
        </div>
        <div v-if="aboutArticle.image" class="border-solid border-l my-8 border-gray-400 hidden md:block"></div>
        <div
          class="flex flex-col justify-center w-full md:w-1/2 p-6 sm:p-12 text-gray-800"
          :class="!aboutArticle.image ? ' text-center' : ''"
        >
          <h3 class="text-2xl font-semibold">{{ aboutArticle.title }}</h3>
          <p class="v-html" v-html="aboutArticle.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useAboutStore } from '../stores/about'

const store = useAboutStore()
const aboutArticles = computed(() => store.list.toSorted((a, b) => (b.priority ?? 0) - (a.priority ?? 0)))
</script>

<style></style>
