<template>
  <div class="p-2 sm:p-12">
    <div class="max-w-screen-lg mx-auto">
      <div class="space-y-1 ul-contact" v-html="contact"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useContactStore } from '../stores/contact'

const store = useContactStore()
const contact = computed(() => store.contact)
</script>

<style lang="scss">
.ul-contact {
  & table,
  & td {
    @apply table-fixed;
    @apply w-full;
    @apply border-collapse;
    @apply border;
    @apply border-gray-500;
  }

  & td {
    @apply p-1;
    @apply text-center;
  }
}
</style>
