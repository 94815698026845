<template>
  <div class="w-full bg-gray-100 min-h-view text-gray-600 h-view overflow-auto">
    <!-- DESKTOP VERSION -->
    <div class="max-w-screen-lg hidden md:flex flex-row mx-auto">
      <div class="w-1/6 flex flex-col pl-4 pt-16 mr-3 min-h-view">
        <button
          v-for="(infoArticle, idx) in infoArticles"
          :key="idx"
          @click="setActive(idx)"
          class="text-md font-medium p-1 text-left focus:outline-none"
          :class="[idx == selectedIdx ? 'font-bold text-gray-900' : '']"
        >
          {{ infoArticle.title }}
        </button>
      </div>
      <div class="border my-16"></div>
      <div class="w-5/6 p-16 text-gray-800 h-view overflow-auto" v-if="activeInfoArticle">
        <div class="flex flex-row items-center pb-4">
          <PhosphorIcon :iconName="activeInfoArticle.icon || 'info'" :size="40" />
          <h1 class="text-xl font-bold px-4">{{ activeInfoArticle.title }}</h1>
        </div>
        <div class="v-html" v-html="activeInfoArticle.text" />
      </div>
    </div>
    <!-- MOBILE VERSION -->
    <div class="md:hidden sm:my-4 sm:mx-8">
      <div v-for="(infoArticle, idx) in infoArticles" :key="idx" class="w-full border-b border-gray-400">
        <div
          @click="toggleActive(idx)"
          class="w-full h-16 flex text-lg cursor-pointer items-center"
          :class="idx === selectedIdx && active ? 'bg-blue-200 font-semibold text-black' : ''"
        >
          <PhosphorIcon :iconName="infoArticle.icon || 'info'" :size="32" class="px-4" />
          <!-- Title -->
          <div class="flex flex-grow h-16 items-center">
            <span>{{ infoArticle.title }}</span>
          </div>
          <!-- Chevron (arrow) -->
          <svg
            viewBox="0 0 48 48"
            class="w-8 h-8 m-4 transform duration-200"
            :class="idx === selectedIdx && active ? '-rotate-180 text-black' : ' text-gray-600'"
          >
            <path fill="currentColor" d="m14.83,16.42l9.17,9.17l9.17,-9.17l2.83,2.83l-12,12l-12,-12l2.83,-2.83Z" />
          </svg>
        </div>
        <div
          class="overflow-y-scroll overflow-x-hidden ul-scroll transition-all duration-300 text-gray-700 bg-gray-200 shadow-inner"
          :class="idx === selectedIdx && active ? 'max-h-1/4-screen' : 'max-h-0'"
        >
          <div class="p-4 v-html" v-html="infoArticle.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import PhosphorIcon from '../components/PhosphorIcon.vue'
import { useInformationStore } from '../stores/information'
import { InformationArticle } from '../types'

const store = useInformationStore()
const infoArticles = computed(() => store.list.toSorted((a, b) => (b.priority ?? 0) - (a.priority ?? 0)))

const selectedIdx = ref(0)
const active = ref(false)

const activeInfoArticle = computed<InformationArticle | undefined>(() => infoArticles.value[selectedIdx.value])

function setActive(index: number) {
  selectedIdx.value = index
}

function toggleActive(index: number) {
  if (selectedIdx.value === index && active.value) {
    active.value = false
    selectedIdx.value = -1
  } else {
    active.value = true
    selectedIdx.value = index
  }
}
</script>

<style></style>
