import { getAuth } from 'firebase/auth'
import { defineComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Information from '../views/Information.vue'
import Login from '../views/Login.vue'
import Program from '../views/Program.vue'
import Resources from '../views/Resources.vue'

type CustomRoute = {
  path: string
  name: string
  component: ReturnType<typeof defineComponent>
  hidden?: boolean
  hideTitle?: boolean
}

export const routes: CustomRoute[] = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    hidden: true,
  },
  {
    path: '/',
    name: 'Hjem',
    component: About,
    hideTitle: true,
  },
  {
    path: '/informasjon',
    name: 'Informasjon',
    component: Information,
  },
  {
    path: '/program',
    name: 'Program',
    component: Program,
  },
  {
    path: '/ressurser',
    name: 'Ressurser',
    component: Resources,
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Contact,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.path !== '/login'
  const currentUser = getAuth().currentUser

  if (typeof to.query.to === 'string') {
    localStorage.setItem('to', to.query.to)
  }

  if (requiresAuth && !currentUser) {
    return next(`/login?to=${to.path}`)
  }

  if (!requiresAuth && currentUser) {
    // if you're logged in, and you're trying to open '/login'
    const toPath = localStorage.getItem('to')
    if (toPath) {
      // if you had a thing planned after login, from earlier
      localStorage.removeItem('to')
      return next(toPath)
    }

    return next('/')
  }

  if (!to.matched.length) {
    // if you try a path that doesn't exist
    next('/')
  }

  // TODO: figure out typing on this
  const matched = to.matched as unknown[] as CustomRoute[]

  if (matched.length > 0 && matched[0].hideTitle) {
    document.title = `UL Medarbeider - ${matched[0].name}`
  } else {
    document.title = 'UL Medarbeider'
  }

  next()
})

export default router
