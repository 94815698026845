import { get, getDatabase, ref } from 'firebase/database'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { onMounted, reactive, ref as vueRef } from 'vue'

import { Item, NoId } from '../types'

export function useListStore<T extends Item>(key: string) {
  const store = defineStore(key, () => {
    const list = reactive([]) as T[]
    const populated = vueRef(false)

    onMounted(async () => {
      if (populated.value) return
      await populate()
      populated.value = true
    })

    async function populate() {
      const db = getDatabase()
      const dbRef = ref(db, key)
      const snapshot = await get(dbRef)

      if (!snapshot.exists()) {
        throw new Error(`Database did not return anything. (key: ${key})`)
      }

      const data = snapshot.val() as Record<string, NoId<T>>
      const newList = Object.entries(data).map(([key, value]) => ({
        ...value,
        id: key,
      })) as T[]

      list.length = 0
      list.push(...newList)
    }

    return { list, populate }
  })

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
  }

  return store
}
