<template>
  <div id="app" class="w-full h-screen">
    <Navigation v-if="loggedIn" />
    <router-view class="w-full bg-gray-100 overflow-y-auto ul-scroll" :class="loggedIn ? 'h-view' : 'h-screen'" />
  </div>
</template>

<script setup lang="ts">
import { getAuth } from 'firebase/auth'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import Navigation from './components/Navigation.vue'
import { useRootStore } from './stores/root'

const rootStore = useRootStore()
const route = useRoute()
const auth = getAuth()

const loggedIn = computed(() => route.name !== 'login')

onMounted(async () => {
  if (auth.currentUser) {
    await rootStore.populate()
  }
})
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

.ul-scroll {
  &::-webkit-scrollbar {
    @apply w-2;
    @apply bg-gray-200;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-400;
  }

  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500;
  }

  &::-webkit-scrollbar-thumb:active {
    @apply bg-gray-600;
  }
}
</style>
