<template>
  <div class="w-full bg-gray-100 min-h-view text-gray-600 h-view overflow-auto">
    <!-- DESKTOP VERSION -->
    <div class="max-w-screen-lg hidden lg:flex flex-row mx-auto h-view px-4">
      <div class="w-1/6 py-16 mr-3 h-view">
        <div class="flex flex-col h-full w-full overflow-auto ul-scroll">
          <button
            v-for="resource in resources"
            :key="resource.id"
            @click="setActive(resource.id)"
            class="text-md font-medium p-1 text-left focus:outline-none"
            :class="[resource.id === selectedId ? 'font-bold text-gray-900' : '']"
          >
            {{ resource.title }}
          </button>
        </div>
      </div>
      <div class="border my-16"></div>
      <div class="w-5/6 pl-16 pt-16 text-gray-800 h-view overflow-auto" v-if="activeResource">
        <h1 class="text-xl font-bold">
          {{ activeResource.title }}
          <span v-if="activeResource.subtitle">- {{ activeResource.subtitle }}</span>
        </h1>
        <iframe
          v-if="isPdf"
          :src="getFileURL(activeResource.filename)"
          frameborder="0"
          class="w-full mt-4"
          style="height: calc(100vh - 236px)"
        />
        <div class="h-144 items-center mt-4" v-else-if="isImage">
          <img :src="getFileURL(activeResource.filename)" class="max-w-full max-h-full" />
        </div>
        <div v-else class="text-left w-full mt-4 text-lg">
          <p>.{{ fileType }}-filer kan ikke forhåndsvises.</p>
          <p>
            <a :href="getFileURL(activeResource.filename)" target="_blank" class="underline text-blue-600">Klikk her</a>
            for å laste den ned istedet.
          </p>
        </div>
      </div>
    </div>
    <!-- MOBILE VERSION -->
    <div class="lg:hidden sm:my-4 sm:mx-8">
      <div class="flex flex-row flex-wrap justify-center">
        <a
          v-for="resource in resources"
          :key="resource.id"
          class="w-64 p-4 pr-16 h-20 m-2 shadow bg-white relative cursor-pointer outline-none text-left"
          :href="getFileURL(resource.filename)"
          rel="noopener noreferrer"
          target="_blank"
        >
          <h3 class="font-semibold">{{ resource.title }}</h3>
          <h4 class="italic">{{ resource.subtitle }}</h4>
          <!-- Open in new tab-icon -->
          <svg viewBox="0 0 24 24" class="absolute top-0 right-0 h-20 w-20 p-6 text-gray-500">
            <path
              fill="currentColor"
              d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'

import { useResourceStore } from '../stores/resources'
import { Resource } from '../types'

const store = useResourceStore()
const selectedId = ref<string | null>(null)

const resources = computed(() =>
  store.list
    .toSorted((a, b) => (a.subtitle > b.subtitle ? 1 : -1))
    .toSorted((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0))
)

const activeResource = computed<Resource | undefined>(() =>
  resources.value.find((resource) => resource.id === selectedId.value)
)

const fileType = computed(() =>
  activeResource.value?.filename
    ? activeResource.value.filename.split('.')[activeResource.value.filename.split('.').length - 1]
    : null
)

const isPdf = computed(() => fileType.value === 'pdf')
const isImage = computed(() => ['jpg', 'jpeg', 'png', 'gif', 'webm'].includes(fileType.value!))

function setActive(id: string) {
  selectedId.value = id
}
function getFileURL(filename: string) {
  return (
    'https://firebasestorage.googleapis.com/v0/b/ulno-1557913587891.appspot.com/o/resources%2F' +
    encodeURI(filename) +
    '?alt=media'
  )
}
function nullcheckAndSetKey() {
  if (selectedId.value === null && resources.value.length) {
    setActive(resources.value[0].id)
  }
}
onMounted(() => nullcheckAndSetKey())

watch(resources, nullcheckAndSetKey, { deep: true })
watch(selectedId, nullcheckAndSetKey, { deep: true })
</script>

<style lang="scss"></style>
