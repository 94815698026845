<template>
  <nav class="h-16 w-full sticky top-0 z-10 bg-gray-100 shadow pt-1 text-right" style="padding-top: 2px">
    <router-link to="/">
      <img src="@/assets/logo.svg" alt="UL-logo" class="left-3 top-3 absolute h-10" />
    </router-link>
    <div class="text-right text-gray-800">
      <router-link
        v-for="(navLocation, idx) in navLocations"
        :key="idx"
        :to="navLocation.path"
        class="nav-location hidden lg:inline-block duration-300 cursor-pointer select-none min-w-16 py-4 px-3 font-semibold text-center border-b-2 border-solid border-transparent hover:text-blue-800 last:mr-2"
        exact-path
        active-class="nav-location-active text-blue-800 border-blue-800/75"
      >
        {{ navLocation.name }}
      </router-link>
      <a
        href="/loggut"
        @click.prevent="auth.logout"
        class="nav-location hidden lg:inline-block duration-300 cursor-pointer select-none min-w-16 py-4 px-3 font-semibold text-center border-b-2 border-solid border-transparent hover:text-blue-800 last:mr-2"
      >
        Logg ut
      </a>
    </div>
    <div @click="open = true" class="lg:hidden inline-block m-3 rounded hover:bg-gray-300 cursor-pointer">
      <Icon icon="menu" class="h-10 text-gray-600 hover:text-black" />
    </div>
    <div
      sidebar-backdrop
      @click="open = false"
      class="lg:hidden fixed inset-0 bg-black bg-opacity-50"
      :class="[open ? '' : 'hidden']"
    />
    <div
      sidebar
      class="lg:hidden fixed h-screen top-0 bg-gray-100 w-72 duration-200 flex flex-col p-4 space-y-2 overflow-auto ul-scroll text-gray-800 hover:text-blue-800"
      :class="[open ? 'shadow' : '']"
      :style="`right: ${open ? '0' : '-30rem'}; max-width: 80vw;`"
    >
      <router-link
        @click.native="open = false"
        v-for="(navLocation, idx) in navLocations"
        :key="idx"
        :to="navLocation.path"
        class="nav-location lg:hidden duration-300 cursor-pointer select-none py-3 px-3 font-semibold text-center border-b-2 border-solid border-transparent rounded"
        active-class="nav-location-active !text-blue-100 !hover:text-blue !border-blue-100/75 !bg-blue-900"
        tag="div"
      >
        {{ navLocation.name }}
      </router-link>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useAuth } from '../hooks/useAuth'
import { routes } from '../router/index'

const auth = useAuth()

const navLocations = routes.filter((route) => !route.hidden)
const open = ref(false)
</script>

<style></style>
