import { get, getDatabase, ref } from 'firebase/database'
import { defineStore } from 'pinia'
import { ref as vueRef } from 'vue'

export const useContactStore = defineStore('contact', () => {
  const contact = vueRef('')

  async function populate() {
    const db = getDatabase()
    const dbRef = ref(db, 'contact')
    const snapshot = await get(dbRef)

    if (!snapshot.exists()) throw new Error('Database did not return anything.')

    const data = snapshot.val() as string
    contact.value = data
  }

  return { contact, populate }
})
