export const DATABASE_URL = import.meta.env.PROD
  ? 'https://ul-medarbeider-prod.europe-west1.firebasedatabase.app/'
  : 'https://ul-medarbeider-dev.europe-west1.firebasedatabase.app/'

export const firebaseConfig = {
  apiKey: 'AIzaSyDVkBvAJqkTYkEhA2j6VuWgH2HobUsqzKY',
  authDomain: 'ulno-1557913587891.firebaseapp.com',
  databaseURL: DATABASE_URL,
  projectId: 'ulno-1557913587891',
  storageBucket: 'ulno-1557913587891.appspot.com',
  messagingSenderId: '676248622318',
  appId: '1:676248622318:web:f29d36155596fc7e69cfff',
}
