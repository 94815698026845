<template>
  <div class="bg-gray-100">
    <div class="md:max-w-screen-xl md:mx-auto min-h-view">
      <div class="w-full flex flex-col justify-center items-center sm:pt-4 xl:pt-8">
        <div
          class="w-full flex flex-row justify-center shadow rounded-t bg-gray-200 duration-200"
          :class="activeEventIdx === 0 ? 'rounded-b' : ''"
        >
          <div
            v-for="(day, idx) in days"
            :key="day.name"
            class="w-1/5 h-12 sm:h-24 cursor-pointer flex justify-center items-center duration-200 relative first:rounded-tl last:rounded-tr hover:bg-opacity-75 rounded"
            :class="[
              selectedDay == idx
                ? 'bg-blue-300 text-blue-900 font-bold shadow-lg rounded-md z-1 '
                : 'text-gray-600 font-semibold hover:bg-blue-200',
              activeEventIdx === 0 ? 'first:rounded-bl last:rounded-br' : '',
            ]"
            @click="selectDay(idx)"
          >
            <span class="hidden sm:inline-block">{{ day.name }}</span>
            <span class="sm:hidden">{{ day.name.substr(0, 2) }}</span>
          </div>
        </div>
        <div class="w-full flex flex-col">
          <div
            v-for="(event, idx) in day"
            :key="event.title + event.timestamp + idx"
            class="w-full flex flex-col duration-200 shadow cursor-pointer last:rounded-b py-2"
            :class="[
              idx + 1 === activeEventIdx ? 'rounded-b' : '',
              idx === activeEventIdx ? 'my-4 rounded bg-blue-200' : 'odd:bg-gray-100 even:bg-gray-200',
              idx - 1 === activeEventIdx ? 'rounded-t' : '',
            ]"
            @click="toggleEvent(event.id)"
          >
            <div class="flex flex-row">
              <div class="w-32 sm:w-48 flex flex-grow justify-center items-center">
                <img :src="event.image" alt="" class="h-16 w-16 object-cover rounded-full duration-300" />
              </div>
              <div class="w-full text-gray-800 flex flex-col justify-center pr-2">
                <p class="text-gray-600 max-w-full">
                  {{ getTimestampString(event.timestamp) }}
                </p>
                <p class="font-bold max-w-full">{{ event.title }}</p>
                <p class="text-gray-600 max-w-full">{{ event.location }}</p>
              </div>
            </div>
            <div
              class="w-full flex py-4 px-4 text-gray-700 items-center text-left"
              :class="idx === activeEventIdx && event.description ? '' : 'hidden'"
            >
              <p class="v-html" v-html="event.description" />
            </div>
          </div>
          <h3 v-if="day.length === 0" class="w-full py-8 text-center text-gray-500 text-xl sm:text-2xl">
            Ingen hendelser denne dagen
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import aktiviteter from '../assets/program/Aktiviteter.png'
import annet from '../assets/program/Annet.png'
import møte from '../assets/program/Møte.png'
import seminar from '../assets/program/Seminar.png'
import underholdning from '../assets/program/Underholdning.png'
import { useEventStore } from '../stores/events'
import { Event } from '../types'

const days = [
  { name: 'Mandag' },
  { name: 'Tirsdag' },
  { name: 'Onsdag' },
  { name: 'Torsdag' },
  { name: 'Fredag' },
  { name: 'Lørdag' },
  { name: 'Søndag' },
]

const store = useEventStore()

const selectedDay = ref(0)
const activeEventId = ref<string | null>(null)

const activeEventIdx = computed(() => day.value.findIndex((event) => event.id === activeEventId.value))

const week = computed(() => {
  const week: Event[][] = [[], [], [], [], [], [], []]
  const programPosts = store.list.toSorted((a, b) => (a.timestamp >= b.timestamp ? 1 : -1))
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  for (const programPost of programPosts) {
    // If the programPost does not have an image, supply a default-image for its type.
    if (!programPost.image) {
      programPost.image = getProgramPostImage(programPost.type)
    }
    const day = days[new Date(programPost.timestamp).getDay()]
    switch (day) {
      case 'monday':
        week[0].push(programPost)
        break
      case 'tuesday':
        week[1].push(programPost)
        break
      case 'wednesday':
        week[2].push(programPost)
        break
      case 'thursday':
        week[3].push(programPost)
        break
      case 'friday':
        week[4].push(programPost)
        break
      case 'saturday':
        week[5].push(programPost)
        break
      case 'sunday':
        week[6].push(programPost)
        break
    }
  }
  return week
})

const day = computed(() => week.value[selectedDay.value])

function toggleEvent(id: string) {
  if (id == activeEventId.value) {
    activeEventId.value = null
  } else {
    activeEventId.value = id
  }
}
function selectDay(idx: number) {
  selectedDay.value = idx
  activeEventId.value = null
}
function getTimestampString(timestamp: string) {
  const date = new Date(timestamp)
  return getDayString(date.getDay()) + ' ' + date.toLocaleTimeString().substring(0, 5)
}
function getDayString(dayNum: number) {
  return ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'][dayNum]
}
function getProgramPostImage(type: string) {
  // return require('../assets/program/Møte.png')
  switch (type) {
    case 'Møte':
      return møte
    case 'Seminar':
      return seminar
    case 'Aktivitet':
      return aktiviteter
    case 'Underholdning':
      return underholdning
    case 'Annet':
    default:
      return annet
  }
}
</script>

<style lang="scss"></style>
